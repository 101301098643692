<template>
  <el-dialog
    :title="`會員持有的${cardTypeTitle}`"
    :visible.sync="syncShow"
    :append-to-body="true"
    destroy-on-close
    width="600px"
    @close="handleClose"
  >
    <div v-if="cardType === 'punchCard'">
      <p class="base">只能選擇「符合當前門市與進場時段」的次卡</p>
      <BaseElButton type="primary" class="create-btn" @click="$emit('createPunchCard')">新增次卡</BaseElButton>
    </div>
    <div v-if="cardType === 'pointCard'">
      <ul>
        <li>只能扣除「符合當前門市與進場時段」的點數卡</li>
        <li>由上到下為進場時自動扣除的順序 ( 當排序 1 的點數卡餘額耗盡，出場時自動扣除排序 2 的點數卡 )</li>
      </ul>
      <BaseElButton type="primary" class="create-btn" @click="$emit('createPointCard')">新增點數卡</BaseElButton>
    </div>
    <BaseElForm>
      <BaseElFormItem>
        <BaseElRadioGroup class="flex flex-col" v-model="syncValue">
          <BaseElRadio class="mr-0" v-for="item in cardList" :key="get(item.entitlementRecord, 'entitlementId')" :label="get(item.entitlementRecord, 'entitlementId')" :disabled="!item.allowEnter">
            <div class="pointCard">
              <div class="flex justify-between">
                <div class="title">{{ get(item.entitlementRecord, 'entitlementName') }}</div>
                <div class="grid grid-flow-col gap-[4px]">
                  <Tag type="action" v-if="get(item.entitlementRecord, 'entitlementId') === entryEntitlements.priorityEntitlementId" size="small">本次使用此次卡</Tag>
                  <Tag type="info" size="small" v-for="error in item.errorCodes" :key="error">{{ errorContent(error) }}</Tag>
                </div>
              </div>
              <div class="content">餘額<span class="value">{{ item.balance }}</span></div>
              <div class="content">效期<span class="value">{{ get(item.entitlementRecord, 'expiredAt') ? formatDate(get(item.entitlementRecord, 'expiredAt')) : '無期限' }}</span></div>
            </div>
          </BaseElRadio>
        </BaseElRadioGroup>
      </BaseElFormItem>
    </BaseElForm>
  </el-dialog>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { get, filter, map } from 'lodash'
import { useVModel } from '@vueuse/core'
import { entitlementTypeUnitConfig, entitlementTypeConfig, entitlementStartDetailErrorConfig } from '@/config/entryControl'
import { formatDate } from '@/utils/date'
import Tag from '@/components/Tag/Tag.vue'

export default defineComponent({
  name: 'CardDetailDialog',
  components: { Tag },
  props: {
    show: Boolean,
    entryEntitlements: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: String,
      default: '',
    },
    cardType: {
      type: String,
      default: '',
    }
  },
  setup (props, { emit }) {
    const syncValue = useVModel(props, 'value', emit)
    const cardList = computed(() => {
      const list = get(props.entryEntitlements, 'entitlementStartDetails')
      const filteredList = filter(list, (item) => get(item, 'entitlementRecord.entitlementType') === props.cardType)
      return map(filteredList, (item) => {
        return {
          ...item,
          balance: get(item.entitlementRecord, 'availableAmount') ? `${get(item.entitlementRecord, 'availableAmount')} ${cardUnit(get(item.entitlementRecord, 'entitlementType'))}` : '無上限',
        }
      })
    })
    const cardTypeTitle = computed(() => {
      return get(entitlementTypeConfig[props.cardType], 'label')
    })
    const errorContent = (code) => {
      return get(entitlementStartDetailErrorConfig[code], 'label')
    }
    const cardUnit = (type, attr = 'label') => {
      return get(entitlementTypeUnitConfig[type], attr)
    }
    const handleClose = () => {
      emit('close')
    }
    return {
      get,
      cardUnit,
      handleClose,
      syncValue,
      formatDate,
      cardList,
      entitlementTypeConfig,
      cardTypeTitle,
      errorContent,
    }
  },
  computed: {
    syncShow: {
      get () {
        return this.show
      },
      set (data) {
        this.$emit('opend:show', data)
      },
    },
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-form-item {
@apply mb-0;
}

.section-block-title {
@apply text-primary-100 font-medium text-[18px] pb-[12px];
}
::v-deep .el-drawer__header {
@apply mb-0;
}

::v-deep .el-textarea {
@apply w-full;
}
.pointCard {
  @apply p-[20px] rounded-[12px] border-[1px] border-solid border-gray-40 grid gap-[8px];
  .title {
    @apply font-medium text-gray-100 text-normal;
  }
  .content {
    @apply text-gray-60 text-sub;
    .value {
      @apply ml-[12px];
    }
  }
}
::v-deep .label-top {
  @apply grid grid-flow-row;
}
::v-deep .label-top .el-form-item__content {
  @apply !ml-0
}
::v-deep .label-top .el-form-item__content .el-input  {
  @apply max-w-full
}
.create-btn {
  @apply my-[28px];
}
::v-deep .el-radio__input {
  @apply hidden;
}
.is-checked .pointCard {
  @apply border-primary-100 border-[2px]
}
.is-disabled .pointCard {
  @apply bg-gray-40
}
</style>
