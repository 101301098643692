<template>
  <div class="create-class-ticket">
    <div class="card-container wrapper">
      <div
        class="font-bold text-lg text-primary-100"
        style="margin-bottom: 16px"
      >
        新增進場紀錄
      </div>
      <BaseElForm
        ref="formRef"
        label-position="top"
        :model="formData"
        :rules="formRules"
      >
        <div v-if="showGuestInput" class="grid grid-flow-col w-[560px] gap-[8px] items-end">
          <BaseElFormItem label="手機號碼(訪客)" prop="guestInfo.phone">
            <BaseElInput
              v-model="formData.guestInfo.phone"
              class="!w-full"
              placeholder="請輸入"
              @clear="showGuestInput = false"
            />
          </BaseElFormItem>
          <BaseElFormItem label="姓名(訪客)" prop="guestInfo.name">
            <BaseElInput
              v-model="formData.guestInfo.name"
              class="!w-full"
              placeholder="請輸入"
            />
          </BaseElFormItem>
          <BaseElFormItem testName="quickRegister">
            <BaseElButton type="primary" class="w-full" @click="quickRegister">註冊會員</BaseElButton>
          </BaseElFormItem>
        </div>
        <BaseElFormItem v-else label="手機號碼(會員)" prop="member">
          <MemberSearch
            class="w-[560px]"
            :model.sync="formData.member"
            :disabled="!!route.query.memberId"
            @change="onMemberChange"
            @getMember="onGetMember"
          />
        </BaseElFormItem>
        <BaseElFormItem label="進場時間" prop="entryAt" class="w-[560px]">
          <el-date-picker
            v-model="formData.entryAt"
            type="datetime"
            format="yyyy-MM-dd HH:mm"
            placeholder="選擇日期"
            :default-value="new Date()"
            @change="onEntryAtChange"
          />
        </BaseElFormItem>
        <BaseElFormItem label="進場權益" prop="entryEntitlementId" class="w-[560px]">
          <div class="flex gap-[8px]">
            <BaseElSelect v-model="formData.entryEntitlementId" class="flex-1">
              <BaseElSelectOption
                v-for="item in entitlementStartDetailsoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </BaseElSelect>
            <template>
              <BaseElButton v-if="entitlementType !== 'admin'" plain @click="onRowDetail">查看詳情</BaseElButton>
              <BaseElButton v-if="entitlementType === 'pointCard'" type="primary" @click="dialog.pointCard = true">新增點數卡</BaseElButton>
              <BaseElButton v-if="entitlementType === 'punchCard'" type="primary" @click="dialog.punchCard = true">新增次卡</BaseElButton>
            </template>
          </div>
        </BaseElFormItem>
        <BaseElFormItem label="備註" prop="entryNote">
          <BaseElInput
            v-model="formData.entryNote"
            class="w-[560px]"
            type="textarea"
            rows="3"
            placeholder="請輸入"
          />
        </BaseElFormItem>
      </BaseElForm>
    </div>
    <PageFixedFooter
      :confirmLoading="loading"
      @cancel="$router.push({ name: 'EntryControlRecordList', id: branchId })"
      @confirm="handleConfirm"
    />
    <CreatePointCardDialog
      v-if="dialog.pointCard"
      :member="formData.member"
      @close="dialog.pointCard = false"
    />
    <CreatePunchCardDialog
      v-if="dialog.punchCard"
      :member="formData.member"
      @close="dialog.punchCard = false"
    />
    <CardDetailDialog
      :show="dialog.cardDetail"
      :value.sync="formData.entryEntitlementId"
      :entryEntitlements="entryEntitlements"
      :cardType="entitlementType"
      @close="dialog.cardDetail = false"
      @createPunchCard="dialog.punchCard = true"
      @createPointCard="dialog.pointCard = true"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, reactive } from 'vue'
import { noEmptyRules, phoneRules } from '@/validation'
import { usePermissions } from '@/use/permissions'
import { ExecuteEntryControlRecord, GetMemberEntryEntitlements } from '@/api/entryControl'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router/composables'
import formUtils from '@/utils/form'
import { useBaseForm, onFormRulesChangeClearValidate } from '@/use/useForm'
import { formatDate } from '@/utils/date'
import { get, isEmpty, find } from 'lodash'
import MemberSearch from '@/components/Search/MemberSearch.vue'
import { QuickRegister } from '@/api/member'
import CreatePointCardDialog from '@/components/Dialog/CreatePointCardDialog.vue'
import CreatePunchCardDialog from '@/components/Dialog/CreatePunchCardDialog.vue'
import CardDetailDialog from '../components/CardDetailDialog.vue'
import { entitlementTypeConfig, entitlementTypeUnitConfig } from '@/config/entryControl'
export default defineComponent({
  name: 'createEntryRecord',
  components: {
    MemberSearch,
    CreatePointCardDialog,
    CreatePunchCardDialog,
    CardDetailDialog,
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const {
      initFormData, formData, formRef, loading,
    } = useBaseForm()
    const shopId = computed(() => store.getters.shop)
    const branchId = computed(() => route.params.id)
    const { checkAction } = usePermissions()
    const showGuestInput = ref(false)
    const entryEntitlements = ref({})
    const useEntryControl = computed(() => checkAction('admin.entryControlConfig.page'))
    const dialog = reactive({
      pointCard: false,
      punchCard: false,
      cardDetail: false,
    })
    initFormData({
      member: null,
      entryAt: null,
      identity: null,
      guestInfo: {
        phone: null,
        name: null,
      },
      entryEntitlementType: null,
      entryEntitlementId: '',
      entryNote: null,
    })
    const entitlementType = computed(() => {
      const entitlementStartDetails = get(entryEntitlements.value, 'entitlementStartDetails', null)
      if (formData.entryEntitlementId === 'admin') return 'admin'
      if (!isEmpty(entitlementStartDetails)) {
        return get(find(entitlementStartDetails, (item) => get(item.entitlementRecord, 'entitlementId') === formData.entryEntitlementId), 'entitlementRecord.entitlementType')
      }
      return 'admin'
    })
    const entitlementStartDetailsoptions = computed(() => {
      const options = [
        {
          label: '後台新增',
          value: 'admin',
        },
      ]
      const records = get(entryEntitlements.value, 'entitlementStartDetails', null)
      if (records) {
        for (const record of records) {
          const item = get(record, 'entitlementRecord', null)
          const cardType = get(entitlementTypeConfig[item.entitlementType], 'label')
          const cardName = get(item, 'entitlementName', null)
          const allowEnter = get(record, 'allowEnter', null)
          // const cardCode = item.entitlementType === 'pointCard' ? get(item, 'pointCardRecord.code', null) : get(item, 'punchCardRecord.code', null)
          const cardUnit = get(entitlementTypeUnitConfig[item.entitlementType], 'label')
          const availableAmount = get(item, 'availableAmount', null)
          const cardAvailable = allowEnter ? availableAmount ? `餘額 ${item.availableAmount} ${cardUnit}` : '無上限' : '不適用'

          options.unshift({
            // label: `${get(entitlementTypeConfig[item.entitlementType], 'label')} ( 餘額總數 ${item.availableAmount} ${get(entitlementTypeUnitConfig[item.entitlementType], 'label')} )`,
            label: `${cardType} (${cardName}) ${cardAvailable}`,
            value: item.entitlementId,
          })
        }
      }
      return options
    })
    const formRules = computed(() => {
      const rules = {
        member: [noEmptyRules()],
        entryAt: [noEmptyRules()],
        entryEntitlementType: [noEmptyRules()],
        guestInfo: {
          phone: [noEmptyRules(), phoneRules()],
          name: [noEmptyRules()],
        },
      }
      return rules
    })

    onFormRulesChangeClearValidate(formRef, formRules)

    const getMemberEntryEntitlements = async () => {
      if (!formData.member || !formData.entryAt) return
      const [res, err] = await GetMemberEntryEntitlements({
        shopId: shopId.value,
        branchId: branchId.value,
        memberId: formData.member?.id,
        entryAt: formData.entryAt,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      entryEntitlements.value = res
      const priorityEntitlementId = get(res, 'priorityEntitlementId', null)
      const entitlementStartDetails = get(entryEntitlements.value, 'entitlementStartDetails', null)
      if (priorityEntitlementId && entitlementStartDetails) {
        formData.entryEntitlementId = priorityEntitlementId
      } else {
        formData.entryEntitlementId = 'admin'
      }
    }
    const onMemberChange = async (member) => {
      showGuestInput.value = false
      if (formData.entryAt) await getMemberEntryEntitlements()
    }
    const onEntryAtChange = async (date) => {
      if (formData.member) await getMemberEntryEntitlements()
    }
    const onGetMember = (memberList, query) => {
      if (isEmpty(memberList)) {
        showGuestInput.value = true
        formData.guestInfo.phone = query
      }
    }
    const executeEntryControlRecord = async () => {
      const {
        member,
        entryAt,
        // identity,
        guestInfo,
        // entryEntitlementType,
        entryEntitlementId,
        entryNote,
      } = formData
      console.log(entitlementType.value)
      const [, err] = await ExecuteEntryControlRecord({
        shopId: shopId.value,
        memberId: member ? member.id : null,
        entryAt,
        identity: member ? 'member' : 'guest',
        guestInfo: member ? null : guestInfo,
        entryEntitlementType: entitlementType.value,
        entryEntitlementId,
        entryNote,
        branchId: branchId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('新增成功!')
      router.push({ name: 'EntryControlRecordList', id: branchId.value })
    }

    const handleConfirm = async () => {
      loading.value = true
      const pass = await formUtils.checkForm(formRef.value)
      if (!pass) {
        loading.value = false
        return
      }
      await executeEntryControlRecord()
      loading.value = false
    }
    const quickRegister = async () => {
      if (!get(formData, 'guestInfo.phone') || !get(formData, 'guestInfo.name')) {
        window.$message.error('Phone and name are required.')
        loading.value = false
        return
      }
      const [, err] = await QuickRegister({
        shopId: shopId.value,
        phone: formData.guestInfo.phone,
        name: formData.guestInfo.name,
      })
      if (err) {
        window.$message.error(err)
        formData.guestInfo.phone = null
        formData.guestInfo.name = null
        return
      }
      window.$message.success('註冊成功')
      showGuestInput.value = false
    }
    const onRowDetail = () => {
      dialog.cardDetail = true
    }
    onMounted(() => {
      if (route.query.memberId) formData.member = { id: route.query.memberId }
      formData.entryAt = new Date()
    })

    return {
      formData,
      formRules,
      handleConfirm,
      loading,
      formRef,
      formatDate,
      useEntryControl,
      onMemberChange,
      onGetMember,
      showGuestInput,
      quickRegister,
      onEntryAtChange,
      branchId,
      route,
      onRowDetail,
      dialog,
      entitlementStartDetailsoptions,
      entitlementType,
      entryEntitlements,
    }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .el-select .el-input {
  @apply w-full;
}

::v-deep .el-input-number,
::v-deep .el-input-number .el-input,
::v-deep .el-date-editor {
  @apply w-full;
}

.wrapper {
  padding: 20px !important;
  padding-left: 29px !important;
}
</style>
